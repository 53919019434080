import Silver from '../Silver/Silver'
import Gold from '../Gold/Gold'
import Platinum from '../Platinum/Platinum'
import './Services.css'

function Services() {

  return(
    <div className='services'>
      <header className='services-header'>
        <h1>Services</h1>
      </header>
      <section className='services-body'>
        <Silver />
        <Gold />
        <Platinum />
      </section>
    </div>
  )
}

export default Services