import React from 'react'
import './App.css'
import Header from '../Header/Header'
import Intro from '../Intro/Intro'
import Services from '../Services/Services'
import ContactUs from '../ContactUs/ContactUs'



function App() {


  return (
    <div className='App'>
      <Header />
      <Intro />
      <Services />
      <ContactUs />
    </div>
  );
}

export default App;
