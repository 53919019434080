import './ContactUs.css'
import {Link} from 'react-router-dom'

function ContactUs() {

  return (
    <div className='contact-us'>
      <h2 className='contact-us-title'>Contact Us with one below to schedule your first detail!</h2>
      <h4 className='direct-phone'>Direct Phone: (720)490-8630</h4>
      <Link to='https://wa.me/message/WJ3Z2PIUTEIZM1'>
        <h4>WhatsApp</h4>
      </Link>
      <Link to='https://www.instagram.com/castle_rock_auto_spa/'>
        <h4>Instagram</h4>
      </Link>
    </div>
  )
}

export default ContactUs