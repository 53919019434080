import React, { useState, useEffect } from 'react';
import './Intro.css';
import beforeDoor from '../../images/beforeDoor.jpg'
import afterDoor from '../../images/afterDoor.jpg'
import beforeFrontFloor from '../../images/beforeFrontFloor.jpg'
import afterFrontFloor from '../../images/afterFrontFloor.jpg'
import beforeFrontSeat from '../../images/beforeFrontSeat.jpg'
import afterFrontSeat from '../../images/afterFrontSeat.jpg'
import beforeBackSeat from '../../images/beforeBackSeat.jpg'
import afterBackSeat from '../../images/afterBackSeat.jpg'

function Intro() {
  const images = [beforeDoor, afterDoor, beforeFrontFloor, afterFrontFloor, beforeFrontSeat, afterFrontSeat, beforeBackSeat, afterBackSeat];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === images.length - 1 ? 0 : prevImage + 1));
    }, 4000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className='intro-component'>
      <section className='intro-paragraph'>
        <h3 className='intro-paragraph-text'>Castle Rock Mobile Auto Spa, spearheaded by dedicated brothers, provides exceptional mobile car detailing services across Castle Rock, Colorado. Our specialty? Convenience and quality on wheels. Our expert team drives right to your doorstep, delivering meticulous detailing and premium care to your vehicle. From the simplest hand wash to comprehensive detailing packages, our commitment to excellence and client satisfaction remains unwavering, ensuring not just a gleaming vehicle but also a delighted smile upon every customer's face, all from the comfort of their home.</h3>
      </section>
      <div className='slideshow-container'>
        {images.map((image, index) => (
          <img
            key={index}
            className={index === currentImage ? 'slide active' : 'slide'}
            src={image}
            alt={`Slide ${index}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Intro;
