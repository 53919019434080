import './Header.css'

function Header() {

  return (
    <div className='header-component'>
      <h1 className='header-title'>Castle Rock Mobile Auto Spa</h1>
    </div>
  )
}

export default Header