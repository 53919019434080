import './Gold.css'

function Gold() {

  return (
    <div className='gold-service'>
      <h2>Gold Glow Bundle - $150</h2>
      <ul className='gold-list'>
        <li className='gold-option'>Exterior Hand Wash and Dry</li>
        <li className='gold-option'>Wheel and Tire Cleaning</li>
        <li className='gold-option'>Full Interior Detailing (including upholstery and carpet shampooing)</li>
        <li className='gold-option'>Window Cleaning (Inside and Out)</li>
        <li className='gold-option'>Door Jamb Wipe Down</li>
        <li className='gold-option'>Leather Conditioning (if applicable)</li>
        <li className='gold-option'>Dashboard and Center Console Cleaning</li>
        <li className='gold-option'>Paint Sealant Application</li>
      </ul>
    </div>
  )
}

export default Gold