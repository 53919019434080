import './Platinum.css'

function Platinum() {

  return (
    <div className='platinum-service'>
      <h1>Prestige Platinum Package - $200</h1>
      <ul className='platinum-list'>
        <li className='platinum-option'>Exterior Hand Wash and Dry</li>
        <li className='platinum-option'>Wheel and Tire Cleaning</li>
        <li className='platinum-option'>Full Interior Detailing (including upholstery and carpet shampooing)</li>
        <li className='platinum-option'>Window Cleaning (Inside and Out)</li>
        <li className='platinum-option'>Door Jamb Wipe Down</li>
        <li className='platinum-option'>Leather Conditioning (if applicable)</li>
        <li className='platinum-option'>Dashboard and Center Console Cleaning</li>
        <li className='platinum-option'>Clay Bar Treatment for Exterior</li>
        <li className='platinum-option'>Paint Sealant Application</li>
        <li className='platinum-option'>Engine Bay Cleaning</li>
      </ul>
    </div>
  )
}

export default Platinum