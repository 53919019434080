import './Silver.css'

function Silver() {

  return (
    <div className='silver-service'>
      <h2>Silver Shine Service - $75</h2>
      <ul className='silver-list'>
        <li className='silver-option'>Exterior Hand Wash and Dry</li>
        <li className='silver-option'>Wheel and Tire Cleaning</li>
        <li className='silver-option'>Interior Vacuuming</li>
        <li className='silver-option'>Window Cleaning (Inside and Out)</li>
        <li className='silver-option'>Door Jamb Wipe Down</li>
        <li className='silver-option'>Light Interior Wipe Down</li>
      </ul>
    </div>
  )
}

export default Silver